import ApTaskLogoLogo from "../assets/ApTask-Logo.png";
import {Button, CircularProgress, Container, Dialog, Grid, TextField, Typography} from "@mui/material";
import {Controller, SubmitHandler, useForm, useWatch} from "react-hook-form";
import PhoneInputComponent from "../utils/phoneNumberInput";
import React, {useEffect, useRef, useState} from "react";
import {toastMessage} from "../utils/common-functions";
import axios from "axios";

type FormValues = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    language: string;
    accent?: string;
    gender: string;
};

const DemoPage = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
        reset
    } = useForm<FormValues>({
        defaultValues: {
            language: 'english'
        }
    });
    const formRef = useRef<HTMLFormElement>(null);
    const [campaignType, setCampaignType] = useState<string>('');
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [otpModelOpen, setOtpModelOpen] = useState(false);
    const [formData, setFromData] = useState<any>({});
    const otpRefs = useRef<any>(Array(6).fill(null));
    const [otp, setOtp] = useState<string[]>(Array(6).fill(''));
    const [timer, setTimer] = useState<number>(120);
    const [resendActive, setResendActive] = useState<boolean>(false);

    const watchLanguage = useWatch({
        control,
        name: 'language',
    });

    useEffect(() => {
        if (otpModelOpen) {
            let interval: NodeJS.Timeout;

            if (timer > 0) {
                interval = setInterval(() => {
                    setTimer((prevTimer) => prevTimer - 1);
                }, 1000);
            } else {
                setResendActive(true);
            }

            return () => clearInterval(interval);
        }

    }, [timer, otpModelOpen]);

    const submitForm = (type: string) => {
        if (formRef.current) {
            setCampaignType(type);
            setTimeout(() => {
                formRef.current!.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
            }, 200);
        }
    };

    const ensureStartsWithPlus = (inputString: any) => {
        if (!inputString?.startsWith('+')) {
            return '+' + inputString;
        }
        return inputString;
    };

    const verifyOtp = async () => {
        setShowLoader(true);
        if (!formData.phoneNumber) {
            setShowLoader(false);
            toastMessage('error', 'Please Add Phone Number');
        }

        if (otp.length < 6) {
            setShowLoader(false);
            toastMessage('error', 'Enter Valid Otp');
        }

        let otpData = {
            otp: otp.join(""),
            phoneNumber: ensureStartsWithPlus(formData.phoneNumber)
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/otp/verify-otp`, otpData);
            if (response.status === 200) {
                setShowLoader(false);

                if (response.data.data.otpVerified) {
                    const campaignData: {
                        firstName: string, lastName: string, phoneNumber: string, campaignType: string, language: string, gender: string, accent?: string
                    } = {
                        firstName: formData.firstName,
                        lastName: formData.lastName,
                        phoneNumber: ensureStartsWithPlus(formData.phoneNumber),
                        campaignType: campaignType,
                        language: formData.language,
                        gender: formData.gender,
                        accent: formData.accent,
                    };
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/calls/call-to-demo-campaign`, campaignData);
                    setShowLoader(false);
                    if (response?.data) {
                        if (response.status === 201) {
                            toastMessage('error', response?.data);
                        }

                        if (response.status === 200) {
                            toastMessage('success', 'Expect call from JobTalk soon…');
                        }

                        reset();
                    }
                } else {
                    setShowLoader(false);
                    toastMessage('error', response?.data?.message);
                }
            }
        } catch (error) {
            setShowLoader(false);
            console.error(error)
            toastMessage('error');
        }
        setOtpModelOpen(false);
    };

    const handleClose = () => {
        setOtpModelOpen(false);
        setTimer(120);
        setResendActive(false);
    };

    const handleResendOTP = async () => {
        if (!formData.phoneNumber) {
            toastMessage('error', 'Please Add Phone Number first');
            return;
        }
        setShowLoader(true);
        setOtp(Array(6).fill(''));
        setTimer(120);
        setResendActive(false); // Disable the resend button again
        let otpData = {
            phoneNumber: ensureStartsWithPlus(formData.phoneNumber),
            campaignType: campaignType
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/otp/send-otp`, otpData);
            if (response && response.status === 200) {
                setShowLoader(false);
                toastMessage('success', 'Otp resent Successfully');
            } else {
                setShowLoader(false);
                toastMessage('error');
            }
        } catch (e) {
            setShowLoader(false);
            console.error(e)
            toastMessage('error');
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const {value} = e.target;

        if (/^\d*$/.test(value) && value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value && index < otp.length - 1 && otpRefs.current) {
                otpRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0 && otpRefs.current) {
            otpRefs.current[index - 1]?.focus();
        }
    };

    const onSubmitForm: SubmitHandler<FormValues> = async (data: any) => {
        setShowLoader(true);

        if (!data.phoneNumber) {
            setShowLoader(false);
            toastMessage('error', 'Please Add Phone Number');
        }
        setFromData(data);
        setOtp(Array(6).fill(''));
        setTimer(120);
        setResendActive(false);

        let otpData = {
            phoneNumber: ensureStartsWithPlus(data.phoneNumber),
            campaignType: campaignType
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/otp/send-otp`, otpData);
            if (response.data) {
                setShowLoader(false);
                toastMessage('success', 'OTP sent to your phone number.');
                setOtpModelOpen(true);
            } else {
                setShowLoader(false);
                toastMessage('error');
            }
        } catch (e) {
            setShowLoader(false);
            console.error(e)
            toastMessage('error');
        }
    };

    return (
        <div className="flex flex-col h-full pt-12 md:pt-0 md:justify-center items-center bg-gray-50">
            <img className="h-8 w-auto mb-12" src={ApTaskLogoLogo} alt="Logo"/>
            <h1 className="text-4xl font-bold inline bg-gradient-to-r from-blue-400 via-sky-600 to-blue-800 bg-clip-text font-display tracking-tight text-transparent text-center sm:text-5xl md:text-7xl px-3">
                Recruiters Best Assistant
            </h1>
            <p className="mt-6 mb-9 text-sm sm:text-base md:text-lg leading-5 lg:leading-8 text-slate-800 max-w-3xl mx-auto text-center px-3">
                With JobTalk.ai, experience the future of recruitment. Our intelligent AI recruiter handles the heavy
                lifting, ensuring your team finds the perfect candidates effortlessly.
            </p>
            <Container>
                <div className="border p-6 bg-white rounded-lg shadow-xl max-w-3xl mx-auto w-full mb-12 md:mb-0">
                    <form onSubmit={handleSubmit(onSubmitForm)} ref={formRef}
                          className="grid grid-cols-12 gap-6">

                        <div className="col-span-12 md:col-span-6">
                            <label htmlFor="firstName"
                                   className="block text-sm font-semibold text-gray-900 mb-2">First
                                name</label>
                            <input type="text"
                                   placeholder={'Enter your First name'}
                                   {...register('firstName', {required: true})}
                                   className={`border 
                           ${errors.firstName ? 'border-red-500' : 'border-gray-300'
                                   }
                           mt-1 block w-full p-3 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm
                           `}/>
                            {errors.firstName &&
                                <p className="mt-2 text-sm text-red-600" id="name-error">First name is
                                    required</p>}
                        </div>

                        <div className="col-span-12 md:col-span-6">
                            <label htmlFor="lastName"
                                   className="block text-sm font-semibold text-gray-900 mb-2">Last
                                name</label>
                            <input type="text"
                                   placeholder={'Enter your Last name'}
                                   {...register('lastName', {required: true})}
                                   className={`border 
                           ${errors.lastName ? 'border-red-500' : 'border-gray-300'
                                   }
                           mt-1 block w-full p-3 rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm
                           `}/>
                            {errors.lastName &&
                                <p className="mt-2 text-sm text-red-600" id="name-error">Last name is
                                    required</p>}
                        </div>

                        <div className="col-span-12">
                            <label htmlFor="phoneNumber"
                                   className="block text-sm font-semibold text-gray-900 mb-2">Phone
                                number#</label>
                            <Controller
                                name="phoneNumber"
                                control={control}
                                rules={{
                                    required: 'Phone number is required',
                                    validate: (value) => {
                                        const phoneNumberLength = value.replace(/\D/g, '').length;
                                        return phoneNumberLength >= 10 || 'Phone number should be at least 10 digits';
                                    }
                                }}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <PhoneInputComponent
                                        country="us"
                                        value={value}
                                        onChange={onChange}
                                        error={error}
                                    />
                                )}
                            />
                            {errors.phoneNumber && (
                                <p className="mt-2 text-sm text-red-600" id="key-error">Phone number is
                                    required</p>
                            )}
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4">
                            <label htmlFor="language"
                                   className="block text-sm font-semibold text-gray-900 mb-2">Language</label>
                            <select {...register('language', {required: 'Language is required'})}
                                    className={`${
                                        errors.language ? 'border-red-500' : 'border-gray-300'
                                    } block w-full p-3 border bg-white rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}>
                                <option value="english">English</option>
                                <option value="spanish">Spanish</option>
                            </select>
                            {errors.language && <p className="mt-2 text-sm text-red-600">Language is required</p>}
                        </div>

                        <div className="col-span-12 md:col-span-6 lg:col-span-4">
                            <label htmlFor="gender"
                                   className="block text-sm font-semibold text-gray-900 mb-2">Gender</label>
                            <select {...register('gender', {required: 'Gender is required'})}
                                    className={`${
                                        errors.gender ? 'border-red-500' : 'border-gray-300'
                                    } block w-full p-3 border bg-white rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}>
                                <option value="" disabled>Select gender</option>
                                <option value="female">Female</option>
                                <option value="male">Male</option>
                            </select>
                            {errors.gender && <p className="mt-2 text-sm text-red-600">Gender is required</p>}
                        </div>

                        {watchLanguage === 'english' && (
                            <div className="col-span-12 md:col-span-6 lg:col-span-4">
                                <label htmlFor="accent"
                                       className="block text-sm font-semibold text-gray-900 mb-2">Accent</label>
                                <select {...register('accent')}
                                        className={`${
                                            errors.accent ? 'border-red-500' : 'border-gray-300'
                                        } block w-full p-3 border bg-white rounded-lg shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm`}>
                                    <option value="american">American</option>
                                    <option value="british">British</option>
                                </select>
                                {errors.accent && <p className="mt-2 text-sm text-red-600">Accent is required</p>}
                            </div>
                        )}

                        <div
                            className="col-span-12 w-auto flex justify-center gap-3 flex-wrap mt-3 sm:flex-nowrap">
                            <button type="button" disabled={showLoader}
                                    onClick={() => submitForm('python')}
                                    className="rounded-full w-full sm:w-auto px-4 py-3 text-sm bg-indigo-50 font-bold text-indigo-600 shadow-sm ring-1 ring-inset ring-indigo-600 hover:bg-indigo-800 hover:text-white">
                                Call for Python developer contract
                            </button>

                            <button type="button" disabled={showLoader}
                                    onClick={() => submitForm('java')}
                                    className="rounded-full w-full sm:w-auto px-4 py-3 text-sm bg-indigo-50 font-bold text-indigo-600 shadow-sm ring-1 ring-inset ring-indigo-600 hover:bg-indigo-800 hover:text-white">
                                Call for Java developer full time
                            </button>
                        </div>
                    </form>
                    {showLoader &&
                        <div
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                            <CircularProgress/>
                        </div>
                    }
                </div>

                <Dialog
                    open={otpModelOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <div className="flex flex-col pt-6 px-2 md:px-6">
                        <div className="text-2xl font-semibold text-center">
                            We just sent you an SMS
                        </div>
                        <p className="text-center my-6 mx-auto w-full max-w-sm">
                            To start the call, enter the security code we sent to your phone number. It will expire in 2
                            minutes.
                        </p>
                        <Grid container spacing={1} justifyContent="center">
                            {otp.map((digit, index) => (
                                <Grid item key={index}>
                                    <TextField
                                        variant="outlined"
                                        inputProps={{
                                            maxLength: 1,
                                            style: {textAlign: 'center', width: "20px", height: "15px"}
                                        }}
                                        value={digit}
                                        onChange={(e: any) => handleChange(e, index)}
                                        onKeyDown={(e: any) => handleKeyDown(e, index)}
                                        inputRef={(ref: any) => {
                                            if (otpRefs.current) {
                                                otpRefs.current[index] = ref;
                                            }
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        {timer > 0 ? (
                            <Typography variant="body2" color="textSecondary" align="center"
                                        style={{marginTop: '10px'}}>
                                Resend OTP
                                in {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                            </Typography>
                        ) : (
                            <Typography variant="body2" color="textSecondary" align="center"
                                        style={{marginTop: '10px'}}>
                                <Button onClick={handleResendOTP} className={'bg-transparent text-blue-600'}
                                        disabled={!resendActive}>Resend OTP</Button>
                            </Typography>
                        )}
                    </div>
                    <div className='my-2 flex flex-col justify-center items-center gap-2 pb-6 px-6'>
                        <button
                            className="rounded-full w-full px-9 py-3 text-sm bg-indigo-50 font-bold text-indigo-600 shadow-sm ring-1 ring-inset ring-indigo-600 hover:bg-indigo-800 hover:text-white"
                            type={"button"} onClick={verifyOtp}>
                            Submit
                        </button>
                        <button
                            className="font-semibold mt-3 px-3 btn-cancel rounded-full text-sm"
                            type={"button"} onClick={handleClose} autoFocus>
                            Cancel
                        </button>
                    </div>
                </Dialog>
            </Container>
        </div>
    );
};

export default DemoPage;
