import {toast, ToastOptions} from 'react-toastify';

export const toastMessage = (type: string, message = '') => {
  const toastOption: ToastOptions = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  };

  if(type === 'success') {
    toast.success(message || 'Suceess', toastOption);
  } else if(type === 'warning') {
    toast.warning(message || 'Warning', toastOption);
  } else if(type === 'error') {
    toast.error(message || 'Something went wrong, Please try again later', toastOption);
  }
}