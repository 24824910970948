import React from 'react';
import { Link } from 'react-router-dom';
import {Typography} from "@mui/material";

const NotFoundPage: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4">
            {/*<img src={errorImage} alt="404 Error" className="w-64 h-64 mb-8"/>*/}
            <Typography variant="h1" className="text-primary font-mono">404</Typography>
            <Typography variant="h3" className="text-slate-500 mb-4" gutterBottom>Oops! Page Not Found</Typography>
            <Typography variant="h6" className="text-gray-600 mb-8 text-center" gutterBottom>
                It seems the page you’re looking for doesn’t exist.
            </Typography>
            <Link
                to="/dashboard"
                className="bg-primary text-white px-6 py-3 rounded-full text-lg hover:bg-primary-dark transition-colors"
            >Go to Dashboard
            </Link>
        </div>
    );
};

export default NotFoundPage;
