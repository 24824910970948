import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import {
    Typography,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    Button,
    Container,
    Box,
    Stepper,
    Step,
    StepLabel, Grid,
} from '@mui/material';
import { coreMatrix } from "../utils/productivity";
import CalcOutput from "../components/CalcOutput";

interface FormData {
    companyType: string;
    hireType: string;
    recruitersCount: number;
    recruiterCost: number;
    contractLength: number;
    feesPerHire: number;
    placementsPerMonth: number;
    aiReason: string;
    paymentPerRecruiter: number;
    netProfit: number;
    costPerMonth: number;
    jobTalkCostPerRecruiter: number;
}

const steps = [
    'Company Type',
    'Hire Type',
    'Contract Position Details',
    'AI Tool Reason',
    // 'Payment Details',
];

const CalcPage: React.FC = () => {
    const {
        register, reset,
        formState: { errors }
    } = useForm<FormData>();


    const [step, setStep] = useState(0);
    const [yearList, setYearList] = useState([]);
    const [formData, setFormData] = useState<FormData>({
        companyType: '',
        hireType: '',
        recruitersCount: 0,
        recruiterCost: 0,
        contractLength: 0,
        feesPerHire: 0,
        placementsPerMonth: 0,
        aiReason: '',
        paymentPerRecruiter: 0,
        netProfit: 0,
        costPerMonth: 0,
        jobTalkCostPerRecruiter: 500,
    });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [validationError, setValidationError] = useState<any>({
        costPerMonth: '',
        contractLength: '',
        netProfit: '',
        jobTalkCostPerRecruiter: ''
    });

    const onSubmit = (data: FormData) => {
        setFormData(data);
        setIsFormSubmitted(true);
        calculateData(data);
    };

    const calculateData = (data: any) => {
        setFormData(data);

        let totalValueOfOneContractor = 0;
        debugger;
        if(formData?.hireType === 'contractors') {
            /*if (formData?.aiReason === 'reduceCost') {
                console.log('data >>>', data)
                totalValueOfOneContractor = Number(data.costPerMonth) * Number(data.netProfit) * 160;
            } else {
                totalValueOfOneContractor = Number(data.contractLength) * Number(data.netProfit) * 160;
            }*/
            totalValueOfOneContractor = Number(data.contractLength) * Number(data.netProfit) * 160;
        } else {
            totalValueOfOneContractor = Number(formData?.feesPerHire);
        }
        console.log('totalValueOfOneContractor >>>>>>.', totalValueOfOneContractor)

        coreMatrix.map((item: any) => {
            item.increasePlacement = item.increaseInProduction * Number(data.recruitersCount);
            if (formData?.aiReason === 'reduceCost' && formData?.hireType === 'contractors') {
                item.reducedRec = Number(data.recruitersCount) - Math.floor(Number(data.recruitersCount) * (Number(item.reductionInRecruiters) / 100));
                item.increaseProfitAdded = Number(totalValueOfOneContractor) * (Number(item.reducedRec) * Number(item.increaseInProduction));
                item.recruitersCostSavings = Number(Number(data.recruitersCount) - Number(item.reducedRec)) * Number(data.costPerMonth)
            } else {
                item.increaseProfitAdded = item.increasePlacement * Number(totalValueOfOneContractor);
            }
            return item;
        });

        const yearData: any = [];
        for (let i = 0; i < coreMatrix.length; i += 12) {
            yearData.push(coreMatrix.slice(i, i + 12));
        }

        yearData.map((monthList: any) => {
            if (!monthList.totalExtraProfit) {
                monthList.totalExtraProfit = 0;
            }
            if (!monthList.increasePlacement) {
                monthList.increasePlacement = 0;
            }
            if (!monthList.recruiterCount) {
                monthList.recruiterCount = 0;
            }
            if (!monthList.costPerMonth) {
                monthList.costPerMonth = 0;
            }
            if (!monthList.recruitersCostSavings) {
                monthList.recruitersCostSavings = 0;
            }

            monthList.map((item: any) => {
                monthList.totalExtraProfit += item.increaseProfitAdded;
                monthList.increasePlacement += item.increasePlacement;
                monthList.recruitersCostSavings += item.recruitersCostSavings;
                monthList.recruiterCount = Number(data.recruitersCount);
                monthList.costPerMonth = data.recruitersCount * data.costPerMonth;
                return item;
            });

            return monthList;

        })
        setYearList(yearData);
    };

    const checkValidation = (value: any, allowDecimals: boolean = false, name: any): string | null => {
        if (value === '') {
            return 'This field is required';
        } else if (allowDecimals) {
            if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                return 'Please enter a number with 2 decimals';
            }
        } else if (value < 100 && name === 'jobTalkCostPerRecruiter') {
            return 'The amount must be greater than or equal to $100.';
        } else {
            if (!/^\d+$/.test(value)) {
                return 'Please enter a number without decimals';
            }
        }
        return null;
    };

    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        let errorMessage: string | null = null;
        if (['costPerMonth', 'contractLength', 'jobTalkCostPerRecruiter'].includes(name)) {
            errorMessage = checkValidation(value, false, name);
        } else if (name === 'netProfit') {
            errorMessage = checkValidation(value, true, name);
        }
        // Set the error message
        setValidationError((prevErrors: any) => ({
            ...prevErrors,
            [name]: errorMessage,
        }));

        if(name === 'hireType') {
            setIsFormSubmitted(false);
        }
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }) as FormData);

        if (isFormSubmitted) {
            calculateData({
                ...formData,
                [name]: value,
            } as FormData);
        }
    };

    const nextStep = () => {
        setStep((nextStep) => {
            if (nextStep === 0 && formData?.companyType === 'employer') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    hireType: 'fulltime'
                }) as FormData);
                return nextStep + 2; // Skip step 1
            }
            return nextStep + 1;
        });
    };

    const prevStep = () => {
        setStep((prevStep) => {
            if (prevStep === 2 && formData?.companyType === 'employer') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    hireType: 'contractors'
                }) as FormData);
                return prevStep - 2; // Skip step 1
            }
            if (formData?.companyType === 'staffing') {
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    aiReason: ''
                }) as FormData);
            }
            return prevStep - 1;
        });
    };

    const resetForm = () => {
        setIsFormSubmitted(false);
        setStep(0);
        reset();
    }

    return (
        <div className="flex flex-col pt-12 md:pt-0 items-center bg-gray-50">
            <Container maxWidth="md" sx={{mt: 2}}>
                <div className={'w-full flex align-baseline justify-center my-3 relative'}>
                    <h1 className="text-2xl sm:text-4xl md:text-4xl font-bold inline bg-gradient-to-r from-blue-400 via-sky-600 to-blue-800 bg-clip-text font-display tracking-tight text-transparent text-center">
                        JobTalk Calculator
                    </h1>

                    {step !== 0 ?
                        <Button color="warning" variant="contained" className={'right-0'} onClick={resetForm} sx={{mt: 1, position: 'absolute',  textTransform: 'none'}}>
                            Reset
                        </Button> : null
                    }
                </div>

                <Box sx={{p: 4, bgcolor: 'white', borderRadius: 2, boxShadow: 4,}}>
                    <Stepper activeStep={step} alternativeLabel sx={{mb: 4}}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <Box
                        component="form"

                    >
                        {step === 0 && (
                            <div>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    gutterBottom
                                    color="darkslategray"
                                >
                                    Are you a staffing company or a direct employer hiring fulltime candidates for
                                    internal positions:
                                </Typography>
                                <RadioGroup {...register('companyType', {required: true})}
                                            onChange={(e) => handleInputChange(e)}>
                                    <FormControlLabel
                                        value="staffing"
                                        checked={formData?.companyType === 'staffing'}
                                        control={<Radio/>}
                                        label="Staffing Company"
                                        sx={{mt: 2, color: 'darkslategray'}}
                                    />
                                    <FormControlLabel
                                        value="employer"
                                        checked={formData?.companyType === 'employer'}
                                        control={<Radio/>}
                                        label="Direct Employer"
                                        sx={{mt: 2, color: 'darkslategray'}}
                                    />
                                </RadioGroup>
                                {formData?.companyType && (
                                    <Box sx={{display: 'flex', justifyContent: 'right', mt: 4}}>
                                        <Button variant="contained" onClick={nextStep} sx={{px: 5}}>
                                            Next
                                        </Button>
                                    </Box>
                                )}
                            </div>
                        )}

                        {step === 1 && formData?.companyType === 'staffing' && (
                            <div>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    gutterBottom
                                    color="darkslategray"
                                >
                                    Do You Hire:
                                </Typography>
                                <RadioGroup {...register('hireType', {required: true})}
                                            onChange={(e) => handleInputChange(e)}>
                                    <FormControlLabel
                                        value="contractors"
                                        control={<Radio/>}
                                        checked={formData?.hireType === 'contractors'}
                                        label="Contractors"
                                        sx={{mt: 2, color: 'darkslategray'}}
                                    />
                                    <FormControlLabel
                                        value="fulltime"
                                        control={<Radio/>}
                                        checked={formData?.hireType === 'fulltime'}
                                        label="Fulltime"
                                        sx={{mt: 2, color: 'darkslategray'}}
                                    />
                                </RadioGroup>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 4}}>
                                    <Button variant="outlined" onClick={prevStep} sx={{px: 5}}>
                                        Back
                                    </Button>
                                {formData?.hireType?.length > 0 && (
                                        <Button variant="contained" onClick={nextStep} sx={{px: 5}}>
                                            Next
                                        </Button>
                                )}
                                </Box>
                            </div>
                        )}

                        {step === 2 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={9}>
                                    <Typography variant="body1" color="darkslategray">
                                        {formData?.companyType === 'employer'
                                            ? 'How many recruiters do you have?'
                                            : `How many recruiters do you have working on your client’s ${formData?.hireType === 'contractors' ? 'contract' : 'fulltime'} positions?`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Number of recruiters"
                                        {...register('recruitersCount', {required: true})}
                                        InputProps={{
                                            type: 'number',
                                            inputProps: {min: 0, style: {textAlign: 'right'}},
                                        }}
                                        error={!!errors.recruitersCount}
                                        helperText={(errors.recruitersCount)? 'This field is required' : ''}
                                        onChange={handleInputChange}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                fontSize: '0.8rem'
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={9}>
                                    <Typography variant="body1" color="darkslategray">
                                        What is the average cost per month of each recruiter?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Cost per month"
                                        {...register('costPerMonth', {
                                            required: 'This field is required',
                                            pattern: {
                                                value: /^\d+(\.\d+)?$/,
                                                message: 'Please enter a number without decimals'
                                            }
                                        })}
                                        InputProps={{
                                            type: 'number',
                                            inputProps: { min: 0, step: '1', style: { textAlign: 'right' } }
                                        }}
                                        error={!!validationError.costPerMonth}
                                        helperText={validationError.costPerMonth}
                                        onChange={handleInputChange}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                fontSize: '0.8rem'
                                            },
                                        }}
                                    />
                                </Grid>

                                {formData?.hireType === 'contractors' && <>
                                    <Grid item xs={12} md={9}>
                                        <Typography variant="body1" color="darkslategray">
                                            What is the average length of your contractor in months?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Length in months"
                                            {...register('contractLength', {required: true})}
                                            InputProps={{
                                                type: 'number',
                                                inputProps: {min: 0, style: {textAlign: 'right'}},
                                            }}
                                            error={!!validationError.contractLength}
                                            helperText={validationError.contractLength}
                                            onChange={handleInputChange}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    fontSize: '0.8rem'
                                                },
                                            }}
                                        />
                                    </Grid>
                                </>}

                                {formData?.hireType === 'contractors' && <>
                                    <Grid item xs={12} md={9}>
                                        <Typography variant="body1" color="darkslategray">
                                            What is your average net profit per contractor per hr.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Net profit"
                                            {...register('netProfit', {required: true})}
                                            InputProps={{
                                                type: 'number',
                                                inputProps: {min: 0, style: {textAlign: 'right'}},
                                            }}
                                            error={!!validationError.netProfit}
                                            helperText={validationError.netProfit}
                                            onChange={handleInputChange}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    fontSize: '0.8rem'
                                                },
                                            }}
                                        />
                                    </Grid>
                                </>}

                                {formData?.hireType !== 'contractors' && <>
                                    <Grid item xs={12} md={9}>
                                        <Typography variant="body1" color="darkslategray">
                                            What is value/fee/cost you are averaging per hire?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            placeholder="Fees per hire"
                                            {...register('feesPerHire', {required: true})}
                                            InputProps={{
                                                type: 'number',
                                                inputProps: {min: 0, style: {textAlign: 'right'}},
                                            }}
                                            error={!!errors.feesPerHire}
                                            helperText={(errors.feesPerHire) ? 'This field is required' : ''}
                                            onChange={handleInputChange}
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    fontSize: '0.8rem'
                                                },
                                            }}
                                        />
                                    </Grid>
                                </>}

                                <Grid item xs={12} md={9}>
                                    <Typography variant="body1" color="darkslategray">
                                        How many placements per month each recruiter does now?
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        placeholder="Placements per month"
                                        {...register('placementsPerMonth', {required: true})}
                                        InputProps={{
                                            type: 'number',
                                            inputProps: {min: 0, style: {textAlign: 'right'}},
                                        }}
                                        error={!!errors.placementsPerMonth}
                                        helperText={(errors.placementsPerMonth) ? 'This field is required' : ''}
                                        onChange={handleInputChange}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                fontSize: '0.8rem'
                                            },
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 4}}>
                                        <Button variant="outlined" onClick={prevStep} sx={{px: 5}}>
                                            Back
                                        </Button>
                                        {!validationError.costPerMonth && !validationError.contractLength && !validationError.netProfit &&
                                        formData?.placementsPerMonth &&
                                        formData?.costPerMonth &&
                                        formData?.recruitersCount &&
                                        ((formData?.hireType === 'contractors' && formData?.netProfit && formData?.contractLength) ||
                                            (formData?.hireType !== 'contractors' && formData?.feesPerHire)) ? (
                                            <Button variant="contained" onClick={nextStep} sx={{ px: 5 }}>
                                                Next
                                            </Button>
                                        ) : null}

                                    </Box>
                                </Grid>
                            </Grid>
                        )}

                        {step === 3 && (
                            <div>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    gutterBottom
                                    color="darkslategray"
                                >
                                    Why do you want to use AI tool like JobTalk?
                                </Typography>
                                <RadioGroup {...register('aiReason', {required: true})}
                                            onChange={(e) => handleInputChange(e)}>


                                           <FormControlLabel
                                               value="increaseRevenue"
                                               control={<Radio/>}
                                               checked={formData?.aiReason === 'increaseRevenue'}
                                               label={formData?.companyType === 'staffing' ? "I want to increase my revenue and profits and not reduce my # of recruiters." : "I want to see increased placements with same # of recruiters."}
                                               sx={{mt: 2, color: 'darkslategray', display: 'block'}}
                                           />

                                    {formData.companyType !== "employer" ?
                                        <FormControlLabel
                                            value="reduceCost"
                                            control={<Radio/>}
                                            checked={formData?.aiReason === 'reduceCost'}
                                            label={formData?.companyType === 'staffing' ? "I want to reduce recruiting cost and increase revenues also." : 'I want to increase placements and reduce # of recruiters'}
                                            sx={{mt: 2, color: 'darkslategray', display: 'block'}}
                                        />
                                        : null
                                    }

                                </RadioGroup>
                                <div className={'mt-6'}>
                                    <Grid container>
                                        <Grid item xs={12} md={9}>
                                            <Typography variant="body1" color="darkslategray" fontWeight="bold">
                                                How much you are willing to pay for JobTalk per recruiter, per month?
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={3}>
                                            <TextField
                                                size="small"
                                                fullWidth
                                                defaultValue={500}
                                                placeholder="jobTalk cost per recruiter"
                                                {...register('jobTalkCostPerRecruiter', {required: true})}
                                                InputProps={{
                                                    type: 'number',
                                                    inputProps: {min: 0, style: {textAlign: 'right'}},
                                                }}
                                                error={!!validationError.jobTalkCostPerRecruiter}
                                                helperText={validationError.jobTalkCostPerRecruiter}
                                                onChange={handleInputChange}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        fontSize: '0.8rem'
                                                    },
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>

                                <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 4}}>
                                    <Button variant="outlined" onClick={prevStep} sx={{px: 5}}>
                                        Back
                                    </Button>
                                {!validationError.jobTalkCostPerRecruiter && formData?.aiReason && formData.jobTalkCostPerRecruiter ?  <>
                                        <Button variant="contained" type="button" onClick={() => onSubmit(formData)} sx={{px: 5}}>
                                            Submit
                                        </Button>
                                </> : null}
                                </Box>
                            </div>
                        )}
                    </Box>
                </Box>
            </Container>

            <div className="mt-8 w-full max-w-5xl px-4 md:px-12">
                {isFormSubmitted && <CalcOutput calculatedData={yearList} formData={formData}/>}
            </div>
        </div>
    );
};

export default CalcPage;
