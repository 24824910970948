import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import DemoPage from "../pages/DemoPage";
import NotFoundPage from "../pages/404";
import CalcPage from "../pages/Calc";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DemoPage/>} />
        <Route path="/calc" element={<CalcPage/>} />
        <Route path="/404" element={<NotFoundPage/>} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
