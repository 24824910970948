import {
    Box,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import React, {useEffect, useState} from "react";

interface YearData {
    contractRecruiters: number;
    costPerRecruiter?: number;
    contractPlacements: number;
    recruitersCostSavings?: number;
    totalExtraProfit?: number;
    netProfit: number;
    cost: number;
    monthData?: any;
    placementsPerMonth?: any;
}

interface CalculatedData {
    yearData: YearData[];
    totalData: Omit<YearData, 'contractRecruiters'>;
    averageData: Omit<YearData, 'contractRecruiters'>;
}

const CalcOutput = (props: any) => {

    const [calculatedData, setCalculatedData] = useState<CalculatedData>({
        yearData: [],
        totalData: {contractPlacements: 0, netProfit: 0, cost: 0, recruitersCostSavings: 0, totalExtraProfit: 0},
        averageData: {contractPlacements: 0, netProfit: 0, cost: 0, recruitersCostSavings: 0, totalExtraProfit: 0},
    });

    const currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        currency: 'USD',
        minimumFractionDigits: 0,
    });

    const formatNumber = (value: number, minFraction = 0, maxFraction = 0, locale: string = 'en-US') => {
        return new Intl.NumberFormat(locale, {
            maximumFractionDigits: maxFraction,
            minimumFractionDigits: minFraction,
        }).format(value);
    };

  /*  useEffect(() => {
        console.log('props.calculatedData', props.calculatedData)
        const yearData = props.calculatedData.map((year: any[]) => ({
            monthData: year,
            placementsPerMonth: props.formData.placementsPerMonth,
            costPerRecruiter: props.formData.recruitersCount * props.formData.jobTalkCostPerRecruiter,
            contractRecruiters: props.formData.recruitersCount,
            contractPlacements: Number((year.reduce((sum, month) => sum + month.increasePlacement, 0)).toFixed(0)),
            netProfit: year.reduce((sum, month) => sum + month.increaseProfitAdded, 0),
            cost: year.reduce((sum, month) => sum + (props.formData.recruitersCount * props.formData.jobTalkCostPerRecruiter), 0),
        }));

        const totalData = yearData.reduce(
            (acc: { contractPlacements: any; netProfit: any; cost: any; }, year: {
                contractPlacements: any;
                netProfit: any;
                cost: any;
            }) => {
                acc.contractPlacements += year.contractPlacements;
                acc.netProfit += year.netProfit;
                acc.cost += year.cost;
                return acc;
            },
            {contractPlacements: 0, netProfit: 0, cost: 0}
        );

        const averageData = {
            contractPlacements: Number((totalData.contractPlacements / 3).toFixed(0)),
            netProfit: Number((totalData.netProfit / 3).toFixed(0)),
            cost: Number((totalData.cost / 3).toFixed(0)),
        };

        console.log('yearData', yearData)

        setCalculatedData({yearData, totalData, averageData});
    }, [props]);*/

    useEffect(() => {
        console.log(' props.calculatedData',  props.calculatedData)
        const yearData = props.calculatedData.map((year: any[]) => {
              return {
                monthData: year,
                placementsPerMonth: props.formData.placementsPerMonth,
                costPerRecruiter: props.formData.recruitersCount * props.formData.jobTalkCostPerRecruiter,
                contractRecruiters: props.formData.recruitersCount,
                contractPlacements: Number((year.reduce((sum, month) => sum + month.increasePlacement, 0)).toFixed(0)),
                netProfit: year.reduce((sum, month) => sum + month.increaseProfitAdded, 0),
                cost: year.reduce((sum, month) => sum + (props.formData.recruitersCount * props.formData.jobTalkCostPerRecruiter), 0),
                recruitersCostSavings: Number((year.reduce((sum, month) => sum + (month.recruitersCostSavings || 0), 0)).toFixed(0)),
                totalExtraProfit: Number((year.reduce((sum, month) => sum + (month.totalExtraProfit || 0), 0)).toFixed(0)),
            };
        });

        console.log('yearData==================', yearData);

        const totalData = yearData.reduce(
            (acc: { contractPlacements: any; netProfit: any; cost: any; recruitersCostSavings: any; totalExtraProfit: any }, year: {
                contractPlacements: any;
                netProfit: any;
                cost: any;
                recruitersCostSavings: any;
                totalExtraProfit: any;
            }) => {
                acc.contractPlacements += year.contractPlacements;
                acc.netProfit += year.netProfit;
                acc.cost += year.cost;
                acc.recruitersCostSavings += year.recruitersCostSavings;
                acc.totalExtraProfit += year.totalExtraProfit;
                return acc;
            },
            { contractPlacements: 0, netProfit: 0, cost: 0, recruitersCostSavings: 0, totalExtraProfit: 0 }
        );

        console.log('=totalDatatotalDatatotalDatatotalData', totalData)

        const averageData = {
            contractPlacements: Number((totalData.contractPlacements / 3).toFixed(0)),
            netProfit: Number((totalData.netProfit / 3).toFixed(0)),
            cost: Number((totalData.cost / 3).toFixed(0)),
            recruitersCostSavings: Number(Number(Number(totalData.recruitersCostSavings) / 3).toFixed(0)),
            totalExtraProfit: Number(Number(Number(totalData.totalExtraProfit) / 3).toFixed(0)),
        };


        setCalculatedData({ yearData, totalData, averageData });
    }, [props]);


    const {yearData, totalData, averageData} = calculatedData;

    return (
        <Container>
            <Box sx={{p: 4, bgcolor: 'white', borderRadius: 2, boxShadow: 4, mt: 3}}>
                <div className="flex flex-col">
                    <h6 className="text-2xl sm:text-4xl md:text-4xl font-bold inline bg-gradient-to-r from-blue-400 via-sky-600 to-blue-800 bg-clip-text font-display tracking-tight text-transparent text-center">
                        Projections for 3 Years
                    </h6>
                </div>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell align="right"><strong>Year 1</strong></TableCell>
                                <TableCell align="right"><strong>Year 2</strong></TableCell>
                                <TableCell align="right"><strong>Year 3</strong></TableCell>
                                <TableCell align="right"><strong>Total</strong></TableCell>
                                <TableCell align="right"><strong>Average <br/> per year</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row"> <strong>Contract Recruiters
                                    Count </strong></TableCell>
                                <TableCell align="right">{props.formData.recruitersCount}</TableCell>
                                <TableCell align="right">{props.formData.recruitersCount}</TableCell>
                                <TableCell align="right">{props.formData.recruitersCount}</TableCell>
                                <TableCell align="right">-</TableCell>
                                <TableCell align="right">-</TableCell>
                            </TableRow>

                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row"> <strong>Number of more contract
                                    placements</strong></TableCell>
                                <TableCell align="right">{formatNumber(yearData[0]?.contractPlacements)}</TableCell>
                                <TableCell align="right">{formatNumber(yearData[1]?.contractPlacements)}</TableCell>
                                <TableCell align="right">{formatNumber(yearData[2]?.contractPlacements)}</TableCell>
                                <TableCell align="right">{formatNumber(totalData?.contractPlacements)}</TableCell>
                                <TableCell align="right">{formatNumber(averageData?.contractPlacements)}</TableCell>
                            </TableRow>

                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row"> <strong>Extra Net Profit Generated from more
                                    contractors</strong></TableCell>
                                <TableCell align="right">{currencyFormatter.format(yearData[0]?.netProfit)}</TableCell>
                                <TableCell align="right">{currencyFormatter.format(yearData[1]?.netProfit)}</TableCell>
                                <TableCell align="right">{currencyFormatter.format(yearData[2]?.netProfit)}</TableCell>
                                <TableCell align="right">{currencyFormatter.format(totalData?.netProfit)}</TableCell>
                                <TableCell align="right">{currencyFormatter.format(averageData?.netProfit)}</TableCell>
                            </TableRow>

                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row"> <strong>Cost of JobTalk
                                    usage</strong></TableCell>
                                <TableCell align="right">{currencyFormatter.format(yearData[0]?.cost)}</TableCell>
                                <TableCell align="right">{currencyFormatter.format(yearData[1]?.cost)}</TableCell>
                                <TableCell align="right">{currencyFormatter.format(yearData[2]?.cost)}</TableCell>
                                <TableCell align="right">{currencyFormatter.format(totalData?.cost)}</TableCell>
                                <TableCell align="right">{currencyFormatter.format(averageData?.cost)}</TableCell>
                            </TableRow>

                            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                <TableCell component="th" scope="row"><strong>NET Extra Income</strong> </TableCell>
                                <TableCell
                                    align="right"><strong>{currencyFormatter.format(yearData[0]?.netProfit - yearData[0]?.cost)}</strong></TableCell>
                                <TableCell
                                    align="right"><strong>{currencyFormatter.format(yearData[1]?.netProfit - yearData[1]?.cost)}</strong></TableCell>
                                <TableCell
                                    align="right"><strong>{currencyFormatter.format(yearData[2]?.netProfit - yearData[2]?.cost)}</strong></TableCell>
                                <TableCell
                                    align="right"><strong>{currencyFormatter.format(totalData?.netProfit - totalData?.cost)}</strong></TableCell>
                                <TableCell
                                    align="right"><strong>{currencyFormatter.format(averageData?.netProfit - averageData?.cost)}</strong></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Box sx={{p: 4, bgcolor: 'white', borderRadius: 2, boxShadow: 4, mt: 3}}>
                <TableContainer component={Paper}>
                    <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader>
                        <TableBody sx={{maxHeight: 500, overflowX: 'auto'}}>
                            {yearData &&
                            yearData.map((row, index) => (
                                <React.Fragment key={index + 'year'}>
                                    <TableRow>
                                        <TableCell colSpan={6} scope="row" align="center">
                                            <h6 className="sm:text-2xl font-bold inline bg-gradient-to-r from-blue-400 via-sky-600 to-blue-800 bg-clip-text font-display tracking-tight text-transparent text-center">
                                                Detailed Projection for Year {index + 1}
                                            </h6>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>

                                        {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors') ?
                                            <>
                                                <TableCell></TableCell>
                                                <TableCell align="right">
                                                    <strong>Red%</strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>Rec</strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>Recruiter Cost <br /> Saving</strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>Prod.Increase</strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>Increase profit added</strong>
                                                </TableCell>
                                            </>
                                            : <>
                                                <TableCell></TableCell>
                                                <TableCell align="right">
                                                    <strong>Prod.Increase</strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>
                                                        # More <br/> Placements
                                                    </strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>
                                                        Increase profit <br/> added
                                                    </strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>Per Recruiter</strong>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <strong>JT Cost to client</strong>
                                                </TableCell>
                                            </>
                                        }

                                    </TableRow>
                                    {row.monthData &&
                                    row.monthData.map((month: any, monthIndex: any) => (
                                        <TableRow
                                            key={monthIndex + 'month'}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                Month {month.month}
                                            </TableCell>
                                            <TableCell align="right">
                                                {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors') ? formatNumber(month.reductionInRecruiters, 2,2) :
                                                    formatNumber(month.increaseInProduction, 2, 2)
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                                {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors') ? formatNumber(month.reducedRec) :
                                                    formatNumber(month.increasePlacement)
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                                {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors') ? currencyFormatter.format(month.recruitersCostSavings) :
                                                    currencyFormatter.format(month.increaseProfitAdded)
                                                }
                                            </TableCell>
                                            <TableCell align="right">
                                                {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors') ? formatNumber(month.increaseInProduction, 2, 2) :
                                                    currencyFormatter.format(
                                                        Math.round(month.increaseProfitAdded / row.contractRecruiters)
                                                    )
                                                }

                                            </TableCell>
                                            <TableCell align="right">
                                                {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors') ? currencyFormatter.format(month.increaseProfitAdded) :
                                                    currencyFormatter.format(Number(row.costPerRecruiter))
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell colSpan={4} scope="row">
                                            *at the end of year {index + 1}, a recruiter who was making
                                            <strong> {row.placementsPerMonth}</strong> placement a month will now make
                                            <strong> {Number(row.monthData[11].increaseInProduction) + Number(row.placementsPerMonth)}</strong> placements
                                            a month
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={index + 'year-summary'}
                                        sx={{
                                            '&:last-child td, &:last-child th': {border: 0},
                                            backgroundColor: 'lightgrey',
                                        }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <strong>Year {index + 1}</strong>
                                        </TableCell>
                                        <TableCell align="right">-</TableCell>
                                        {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors') ? <TableCell align="right">-</TableCell> :
                                          <>
                                              <TableCell align="right">
                                                  <strong>{formatNumber(row.contractPlacements)}</strong>
                                              </TableCell>
                                          </>
                                        }
                                        {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors') ?
                                            <>
                                                <TableCell align="right">
                                                    <strong>{formatNumber(Number(row.recruitersCostSavings))}</strong>
                                                </TableCell>
                                            </> :
                                            <>
                                                {(props.formData.companyType === "employer" && props.formData?.hireType === 'fulltime') ?
                                                    <>
                                                        <TableCell align="right">
                                                            <strong>{formatNumber(Number(row.netProfit))}</strong>
                                                        </TableCell>
                                                    </> :
                                                    <>
                                                        <TableCell align="right">
                                                            <strong>{formatNumber(Number(row.contractPlacements))}</strong>
                                                        </TableCell>
                                                    </>
                                                }

                                            </>
                                        }
                                        <TableCell align="right">-</TableCell>
                                        {(props.formData.aiReason === 'reduceCost' && props.formData?.hireType === 'contractors') ?
                                            <TableCell align="right">
                                                <strong>{currencyFormatter.format(row.netProfit)}</strong>
                                            </TableCell> :
                                            <>
                                                <TableCell align="right">
                                                    <strong>{formatNumber(row.cost)}</strong>
                                                </TableCell>
                                            </>
                                        }
                                        {/*<TableCell align="right">*/}
                                        {/*    <strong>{currencyFormatter.format(row.cost)}</strong>*/}
                                        {/*</TableCell>*/}
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Container>
    );
}

export default CalcOutput;
