export const coreMatrix = [
  {
    month: 1,
    increaseInProduction: 0.00,
    reductionInRecruiters: 0
  },
  {
    month: 2,
    increaseInProduction: 0.00,
    reductionInRecruiters: 0
  },
  {
    month: 3,
    increaseInProduction: 0.00,
    reductionInRecruiters: 5
  },
  {
    month: 4,
    increaseInProduction: 0.25,
    reductionInRecruiters: 5},
  {
    month: 5,
    increaseInProduction: 0.25,
    reductionInRecruiters: 7.5
  },
  {
    month: 6,
    increaseInProduction: 0.50,
    reductionInRecruiters: 7.5},
  {
    month: 7,
    increaseInProduction: 0.50,
    reductionInRecruiters: 10
  },
  {
    month: 8,
    increaseInProduction: 0.75,
    reductionInRecruiters: 10
  },
  {
    month: 9,
    increaseInProduction: 0.75,
    reductionInRecruiters: 15},
  {
    month: 10,
    increaseInProduction: 1.00,
    reductionInRecruiters: 15
  },
  {
    month: 11,
    increaseInProduction: 1.00,
    reductionInRecruiters: 20
  },
  {month: 12,
    increaseInProduction: 1.25,
    reductionInRecruiters: 20
  },
  {
    month: 13,
    increaseInProduction: 1.25,
    reductionInRecruiters: 25
  },
  {
    month: 14,
    increaseInProduction: 1.25,
    reductionInRecruiters: 25},
  {
    month: 15,
    increaseInProduction: 1.25,
    reductionInRecruiters: 25
  },
  {
    month: 16,
    increaseInProduction: 1.25,
    reductionInRecruiters: 30
  },
  {
    month: 17,
    increaseInProduction: 1.50,
    reductionInRecruiters: 30
  },
  {
    month: 18,
    increaseInProduction: 1.50,
    reductionInRecruiters: 30
  },
  {
    month: 19,
    increaseInProduction: 1.50,
    reductionInRecruiters: 35},
  {
    month: 20,
    increaseInProduction: 1.50,
    reductionInRecruiters: 35
  },
  {
    month: 21,
    increaseInProduction: 1.75,
    reductionInRecruiters: 40
  },
  {
    month: 22,
    increaseInProduction: 1.75,
    reductionInRecruiters: 40
  },
  {
    month: 23,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 24,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 25,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 26,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 27,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 28,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 29,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 30,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 31,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 32,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 33,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 34,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 35,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  },
  {
    month: 36,
    increaseInProduction: 2.00,
    reductionInRecruiters: 50
  }
]
