import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneInputComponent: React.FC<any> = ({
  country = 'us',
  value,
  onChange,
  error,
  inputProps = {
    required: true,
    className:
      'mt-1 block w-full pl-16 p-3 rounded-lg border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
  },
}) => {
  const searchStyle = {
    borderRadius: '0.5rem',
    border: '1px solid #d1d5db',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
    padding: '0.5rem',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    width: '88%',
  };

  const buttonStyle = {
    padding: '0 0.5rem',
    borderRadius: '0.5rem 0 0 0.5rem',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#e5e7eb',
    },
  };

  return (
    <div className="w-50px">
      <PhoneInput
        country={country}
        value={value}
        onChange={onChange}
        inputProps={inputProps}
        enableSearch={true}
        buttonStyle={buttonStyle}
        searchStyle={searchStyle}
        searchPlaceholder="Search Countries"
        searchNotFound="No countries found"
      />
      {error && value && (
        <p className="mt-2 text-sm text-red-600" id="phoneNumber-error">
          {error.message}
        </p>
      )}
    </div>
  );
};

export default PhoneInputComponent;
